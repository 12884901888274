import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength);
  }
  return text;
};

const stripHtmlTags = (html, maxLength) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const strippedText = tempDiv.textContent || tempDiv.innerText || "";
  return truncateText(strippedText, maxLength);
};

function Epaper() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showShareOptions, setShowShareOptions] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://localhost:44351/api/home/getEpaper"
        );
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDateInHindi = (dateString) => {
    const date = new Date(dateString);
    const monthsInHindi = [
      "जनवरी",
      "फरवरी",
      "मार्च",
      "अप्रैल",
      "मई",
      "जून",
      "जुलाई",
      "अगस्त",
      "सितंबर",
      "अक्टूबर",
      "नवंबर",
      "दिसंबर",
    ];
    const day = date.getDate();
    const month = monthsInHindi[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const toggleShareOptions = (postId) => {
    setShowShareOptions((prevId) => (prevId === postId ? null : postId));
  };

  if (error) {
    return <div>Error: {error}</div>;
  }
  const baseUrl = "https://localhost:44351/";

  return (
    <div style={{ backgroundColor: "black" }}>
      <HelmetProvider>
        <Helmet>
          <title>सदैव सत्य  | ई-पेपर</title>
          <script defer src="https://cdn.ampproject.org/v0.js"></script>
          <script
            defer
            custom-element="amp-story"
            src="https://cdn.ampproject.org/v0/amp-story-1.0.js"
          ></script>
        </Helmet>
      </HelmetProvider>

      <div className="ak-content" style={{ transform: "none" }}>
        <div style={{ color: "white", marginTop: "20px" }}>
          <picture>
            <img
              className="site-logo"
              width={120}
              height={50}
              loading="lazy"
              src="/design/images/logo-mike1.png"
              alt="सदैव सत्य  Logo"
              style={{ paddingLeft: "10px" }}
            />
          </picture>
          <hr />
        </div>

        <div className="container" style={{ transform: "none", backgroundColor: "black" }}>
          <div className="row" style={{ transform: "none" }}>
            <div className="ak_column_2 col-md-12 content-column">
              <div className="ak-archive-header style-3">
                <div className="container">
                  <div className="ak-archive-header-inner mt-3">
                    <div className="ak-archive-head clearfix">
                      <div className="ak-archive-head-inner">
                        <h1 className="ak-archive-name">
                          <span className="archive-name-text" style={{ color: "white" }}>
                            मेरे पसंदीदा शहर
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mb-3 ak-block ak-block-list-4 ak-block-column ak-block-width-2 ak-pagination-container load_more clearfix ak-pagination-loaded" id="block_66f500455611d_3">
                <div className="ak-block-inner clearfix">
                  <div className="ak-block-posts clearfix">
                    {posts.dataEpapers && posts.dataEpapers.length > 0 ? (
                      posts.dataEpapers.map((newsItem) => (
                        <article className="ak-module ak-module-4 ak-column-module clearfix post-228 post type-post status-publish format-standard has-post-thumbnail  category-business category-entertainment category-featured category-us-news category-work-life tag-breaking tag-election tag-politics tag-technology tag-world-news">
                          <div className="ak-module-inner clearfix">
                            <div className="ak-module-featured">
                              <div className="ak-module-badges" />
                              <div className="ak-featured-cover">
                                <Link
                                  to={`/EpaperDetails/${newsItem.epaperId}`}
                                  className="ak-featured-link"
                                  rel="bookmark"
                                  title="Do We Really Need To Wear Hair Products That Contain Sunscreen?"
                                >
                                  <div className="ak-featured-thumb lazy-thumb size-715">
                                    <img
                                      width={350}
                                      height={250}
                                      src={baseUrl + newsItem.pdfUrlNews}
                                      className="attachment-newsy_350x250 size-newsy_350x250 wp-post-image lazyautosizes lazyloaded"
                                      alt=""
                                      decoding="async"
                                      fetchpriority="high"
                                      data-src={baseUrl + newsItem.pdfUrlNews}
                                      data-sizes="auto"
                                      data-srcset={baseUrl + newsItem.pdfUrlNews}
                                      data-expand={700}
                                      sizes="370px"
                                      srcSet={baseUrl + newsItem.pdfUrlNews}
                                    />
                                  </div>
                                </Link>
                              </div>
                              <div className="ak-module-terms badge">
                                <Link
                                  className="term-45"
                                  to={`/EpaperDetails/${newsItem.epaperId}`}
                                >
                                  {stripHtmlTags(newsItem.titleName, 10)}
                                </Link>
                              </div>
                            </div>
                            <div className="ak-module-details">
                              <h3 className="ak-module-title">
                                <Link
                                  to=""
                                  rel="bookmark"
                                  title="Do We Really Need To Wear Hair Products That Contain Sunscreen?"
                                ></Link>
                              </h3>
                              <div className="ak-module-meta">
                                <div className="ak-module-time">
                                  <Link
                                    to={`/EpaperDetails/${newsItem.epaperId}`}
                                    className="ak-module-meta-published"
                                  >
                                    <i className="ak-icon akfi-schedule" />
                                    {formatDateInHindi(newsItem.date)}
                                  </Link>
                                </div>
                                <div className="ak-module-view-count">
                                  <span style={{ marginLeft: "100px" }}>
                                    <i
                                      className="fa fa-share-alt"
                                      onClick={() => toggleShareOptions(newsItem.epaperId)}
                                      style={{ cursor: "pointer", paddingLeft: "10px", color: "red" }}
                                    ></i>
                                  </span>

                                  {/* Share Options Popup */}
                                  {showShareOptions === newsItem.epaperId && (
                                    <div style={{
                                      backgroundColor: "white",
                                      borderRadius: "5px",
                                      padding: "10px",
                                      position: "absolute",
                                      zIndex: "1000",
                                      marginLeft: "100px"
                                    }}>
                                      <a
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${baseUrl}EpaperDetails/${newsItem.epaperId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginRight: "10px", color: "black" }}
                                      >
                                        <i className="fa fa-facebook" style={{ fontSize: "24px" }}></i>
                                      </a>
                                      <a
                                        href={`https://api.whatsapp.com/send?text=${baseUrl}EpaperDetails/${newsItem.epaperId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginRight: "10px", color: "black" }}
                                      >
                                        <i className="fa fa-whatsapp" style={{ fontSize: "24px" }}></i>
                                      </a>
                                      <a
                                        href={`https://twitter.com/intent/tweet?url=${baseUrl}EpaperDetails/${newsItem.epaperId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginRight: "10px", color: "black" }}
                                      >
                                        <i className="fa fa-twitter" style={{ fontSize: "24px" }}></i>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      ))
                    ) : (
                      <div>No data available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Epaper;
