import React from 'react'
import { Link } from 'react-router-dom'

function ButtonTags() {
  return (
    <div>
      
      <footer className="ak-post-footer">
  <div className="ak-post-tags clearfix">
    <span>सदैव सत्य  पर पढ़े : </span>

    <Link to="/MadhyaPradeshDetails">
    मध्य प्रदेश
    </Link>

    <Link to="/ChhatisgarDetails">
    छत्तीसगढ़
    </Link>

    <Link to="/RashifalDetails">
    राशिफल
    </Link>

    <Link to="/International">
    अंतरराष्ट्रीय
    </Link>

    <Link to="/DaramDetails">
    धर्म
    </Link>
    <Link to="/Khels">
    खेल
    </Link>
    <Link to="/WebstorieDetails">
    वेब स्टोरीज
    </Link>
    <Link to="/Bollywood">
    बॉलीवुड
    </Link>
   

   
  </div>
</footer>


    </div>
  )
}

export default ButtonTags
