import React from 'react';
import Home from './components/Home';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Rashifal from './components/Rashifal';
import TajaKhabr from './components/TajaKhabr';
import LiveNews from './components/LiveNews';
import Footer from './components/Footer';
import Header from './Header';
import Webstorie from './components/Webstorie';
import MpNews from './components/MpNews';
import HealthAndFitness from './components/HealthAndFitness';
import ScrollToTop from './components/ScrollToTop';
import Sports from './components/Sports';
import Card from './components/Card';
import SliderDetails from './components/SliderDetails';
import TajaKhabarDetails from './components/TajaKhabarDetails';
import MadhyaPradeshDetails from './components/MadhyaPradeshDetails';
import RashifalDetails from './components/RashifalDetails';
import WebstorieDetails from './components/WebstorieDetails';
import VideoDetails from './components/VideoDetails';
import DaramDetails from './components/DaramDetails';
import KhelDetails from './components/KhelDetails';
import BuisnessDetails from './components/BuisnessDetails';
import ChhatisgarDetails from './components/ChhatisgarDetails';
import RashiDetail from './components/RashiDetail';
import BusinessDetailsD from './components/BusinessDetailsD';
import Khels from './components/Khels';
import KhelDetailsD from './components/KhelDetailsD';
import Bollywood from './components/Bollywood';
import BollywoodDetails from './components/BollywoodDetails';
import VideoDetailsD from './components/VideoDetailsD';
import DaramDetailsD from './components/DaramDetailsD';
import ScrollTo from './components/ScrollTo';
import WebStrorieD from './components/WebStrorieD';
import DainikRashifalDetails from './components/DainikRashifalDetails';
import TajaKhabarDetailsNews from './components/TajaKhabarDetailsNews';
import International from './components/International';
import InternationalDetails from './components/InternationalDetails';
import MadhyapradeshDetailsD from './components/MadhyapradeshDetailsD';
import OtherState from './components/OtherState';
import OtherStateDetails from './components/OtherStateDetails';
import ChhatisgarDetailsD from './components/ChhatisgarDetailsD';
import RashifalDetailsD from './components/RashifalDetailsD';
import Wether from './components/Wether';
import Epaper from './components/Epaper';
import EpaperDetails from './components/EpaperDetails';

import DemoD from './components/DemoD';
import NotFound from './components/NotFound';

function App() {
  const location = useLocation();

  // Determine if Header and Footer should be rendered
  const shouldRenderHeaderFooter = (path) => {
    // Define paths where Header and Footer should not render
    const excludedPaths = ['/WebStrorieD', '/Epaper'];

    // Check if current path is in the excluded list
    return !excludedPaths.some(excludedPath => path.startsWith(excludedPath));
  };

  return (
    <>
      {shouldRenderHeaderFooter(location.pathname) && <Header />}
      <ScrollTo />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Card" element={<Card />} />
        <Route path="/Rashifal" element={<Rashifal />} />
        <Route path="/Webstorie" element={<Webstorie />} />
        <Route path="/TajaKhabr" element={<TajaKhabr />} />
        <Route path="/MpNews" element={<MpNews />} />
        <Route path="/HealthAndFitness" element={<HealthAndFitness />} />
        <Route path="/Sports" element={<Sports />} />
        <Route path="/SliderDetails/:mainKhabarId" element={<SliderDetails />} />
        <Route path="/TajaKhabarDetails" element={<TajaKhabarDetails />} />
        <Route path="/MadhyaPradeshDetails" element={<MadhyaPradeshDetails />} />
        <Route path="/RashifalDetails" element={<RashifalDetails />} />
        <Route path="/WebstorieDetails" element={<WebstorieDetails />} />
        <Route path="/VideoDetails" element={<VideoDetails />} />
        <Route path="/DaramDetails" element={<DaramDetails />} />
        <Route path="/KhelDetails" element={<KhelDetails />} />
        <Route path="/BuisnessDetails" element={<BuisnessDetails />} />
        <Route path="/ChhatisgarDetails" element={<ChhatisgarDetails />} />
        <Route path="/RashiDetail/:rashifalId" element={<RashiDetail />} />
        <Route path="/BusinessDetailsD/:mainKhabarId" element={<BusinessDetailsD />} />
        <Route path="/Khels" element={<Khels />} />
        <Route path="/KhelDetailsD/:mainKhabarId" element={<KhelDetailsD />} />
        <Route path="/Bollywood" element={<Bollywood />} />
        <Route path="/BollywoodDetails/:mainKhabarId" element={<BollywoodDetails />} />
        <Route path="/VideoDetailsD/:shortVideoId" element={<VideoDetailsD />} />
        <Route path="/DaramDetailsD/:mainKhabarId" element={<DaramDetailsD />} />
        <Route path="/WebStrorieD/:webStoriesId" element={<WebStrorieD />} />
        <Route path="/DainikRashifalDetails" element={<DainikRashifalDetails />} />
        <Route path="/TajaKhabarDetailsNews/:mainKhabarId" element={<TajaKhabarDetailsNews />} />
        <Route path="/International" element={<International />} />
        <Route path="/InternationalDetails/:mainKhabarId" element={<InternationalDetails />} />
        <Route path="/MadhyaPradeshDetailsD/:mainKhabarId" element={<MadhyapradeshDetailsD />} />
        <Route path="/OtherState/:stateId" element={<OtherState />} />
        <Route path="/OtherStateDetails/:mainKhabarId" element={<OtherStateDetails />} />
        <Route path="/ChhatisgarDetailsD/:mainKhabarId" element={<ChhatisgarDetailsD />} />
        <Route path="/Wether" element={<Wether />} />
        <Route path="/LiveNews" element={<LiveNews />} />
        <Route path="/RashifalDetailsD/:dainickRashiId" element={<RashifalDetailsD />} />
        <Route path="/Epaper" element={<Epaper />} />
        <Route path="/EpaperDetails/:epaperId" element={<EpaperDetails />} />
        <Route path="/DemoD" element={<DemoD />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      {shouldRenderHeaderFooter(location.pathname) && <Footer />}
      <ScrollToTop />
    </>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
