import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


function DemoD() {

  // useEffect hook to initialize the carousel after the component mounts

  return (
    <div>
      <div className="col-md-12 text-center">
        <h3>Product Carousel</h3>
      </div>
      <div className="col-md-6 col-md-offset-3">
        <div id="myCarousel" className="carousel slide" data-ride="carousel" data-interval="3000">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Link href="#">
                    <img src="http://placehold.it/500/e499e4/fff&text=1" className="img-responsive" alt="Product 1" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Link href="#">
                    <img src="http://placehold.it/500/e477e4/fff&text=2" className="img-responsive" alt="Product 2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Link href="#">
                    <img src="http://placehold.it/500/eeeeee&text=3" className="img-responsive" alt="Product 3" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Link href="#">
                    <img src="http://placehold.it/500/f4f4f4&text=4" className="img-responsive" alt="Product 4" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default DemoD;
