// VerticalVideo.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from 'react-router-dom'
import { Circles } from "react-loader-spinner";
const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength);
    }
    return text;
  };
  
  const stripHtmlTags = (html, maxLength) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const strippedText = tempDiv.textContent || tempDiv.innerText || "";
    return truncateText(strippedText, maxLength);
  };
  
const VideoDetailsD = () => {
    const { shortVideoId } = useParams();
    const [newsDetails, setNewsDetails] = useState(null);
  
    useEffect(() => {
      const fetchNewsDetails = async () => {
        try {
          const { data } = await axios.get(
            `https://api.shabdtoday.com/api/home/getvideodetails/${shortVideoId}`
          );
          console.log("Fetched data:", data); // Log the fetched data
          setNewsDetails(data);
        } catch (error) {
          console.error("Error fetching news details:", error);
        }
      };
  
      fetchNewsDetails();
    }, [shortVideoId]);
  
    const formatDateInHindi = (dateString) => {
      const date = new Date(dateString);
      const monthsInHindi = [
        "जनवरी",
        "फरवरी",
        "मार्च",
        "अप्रैल",
        "मई",
        "जून",
        "जुलाई",
        "अगस्त",
        "सितंबर",
        "अक्टूबर",
        "नवंबर",
        "दिसंबर",
      ];
      const day = date.getDate();
      const month = monthsInHindi[date.getMonth()];
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    };
  
    if (!newsDetails) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Circles
            height="30"
            width="30"
            color="#f7265c"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      );
    }
  
    const BASE_URL = "https://api.shabdtoday.com/";
    const image_resize = "https://api.shabdtoday.com/api/home/resize";


  return (
    

<div className="bg-black">
<div className="video-container mb-3">
   <Link to="/VideoDetails"><button className="w3-button w3-large w3-circle w3-light-grey" style={{color:"green"}}>‹</button></Link>
      <video  width="100%" height="auto" loop muted playsInline autoPlay controls>
        <source src={`${BASE_URL}${newsDetails.shortVideoPath}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className='mt-3'>

</div>
    </div>
</div>
    
    
  );
};

export default VideoDetailsD;
